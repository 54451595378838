import { inject, Injectable } from '@angular/core';

import { LocationDto, LocationFilterParamsDto } from '../dtos/location.dto';

import { Location, LocationFilterParams } from '../models/location';

import { MapperFromDto } from './mappers';
import { PaginationParamsMapper } from './pagination.mapper';

/** Location mapper. */
@Injectable({
	providedIn: 'root',
})
export class LocationMapper implements MapperFromDto<LocationDto, Location> {
	private readonly paginationMapper = inject(PaginationParamsMapper);

	/** @inheritdoc */
	public fromDto(dto: LocationDto): Location {
		return {
			id: dto.id,
			loc_id: dto.loc_id,
			name: dto.name,
			locationId: dto.loc_id,
			canQuote: dto.can_quote,
			prefix: dto.prefix,
		};
	}

	/**
	 * Map filter params to dto.
	 * @param params Filter params.
	 */
	public toParamsDto(params: LocationFilterParams): LocationFilterParamsDto {
		const pagination = params.pagination ? this.paginationMapper.toDto(params.pagination) : {};
		return {
			name: params.name,
			in_equipment_manager: params.isInEquipmentManager,
			...pagination,
		};
	}
}
