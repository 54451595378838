import { z } from 'zod';

import { BaseFilterParams } from './base-filter-params';

/** Location schema. */
export const locationSchema = z.object({

	/** Id. */
	id: z.number(),

	/** Location Id */
	loc_id: z.string(),

	/** Name. */
	name: z.string(),

	/** Location unique identifier string. */
	locationId: z.string(),

	/** Can quote. */
	canQuote: z.boolean(),

	/** Location prefix. */
	prefix: z.string(),
});

/** Location. */
export type Location = Readonly<z.infer<typeof locationSchema>>;

/** Location filter params . */
export type LocationFilterParams = {

	/** Name. */
	readonly name?: string;

	/** Whether location is in equipment manager. */
	readonly isInEquipmentManager?: boolean;

	/** Pagination. */
	readonly pagination?: BaseFilterParams.Pagination;
};
