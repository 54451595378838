<mat-card class="card">
	<mat-card-header
		class="card-header"
		[class.has-divider]="hasDivider"
	>
		<mat-card-title
			class="card-header__title cursor"
			(click)="titleClicked.emit()"
		>
			<div #ref><ng-content select="[title]"></ng-content></div>
			@if (!ref.hasChildNodes()) {
				<ng-container>
					{{ title }}
				</ng-container>
			}
			<ng-content select="[titleExtraContent]"></ng-content>
		</mat-card-title>
		<div>
			<ng-content select="[headerActions]"></ng-content>
		</div>
	</mat-card-header>
	<mat-card-content class="card-content" [style.max-height]="maxHeight" [style.overflow-y]="overflowY">
		<div [class.has-spacing]="hasSpacing">
			<ng-content select="[content]"></ng-content>
		</div>
	</mat-card-content>
</mat-card>
